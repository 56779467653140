@import "variables";



html, body { height: 100%; }
body { 
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: $color_darkgray;
    background-color: $color_beige;
    @media all and (min-width: map-get($grid-breakpoints, xl)) {
        font-size: 1.3em;
    }
}

.container .row > div, .container-fluid .row > div {
    padding: 5vw;
}

h2 {
    margin-top: 5vw;
    &:first-of-type {
        margin-top: 0;
    }
}

.#{$prefix}page {
    // min-height: 100vh;
    // border: solid black 1px;
    .row {
        // min-height: 100vh;
        align-items: center;
    }
}

// .#{$prefix}col2 {
//     flex: 1 100%;
//     padding: 5vw;
//     @media all and (min-width: 800px) {
//         flex: 1 50%;
//     }
// }

.#{$prefix}hometitle {
    min-height: 100vh;
    background: url(../assets/agricola.jpg);
    background-size: cover;
    background-position: center;
    .logo {
        position: absolute;
        right: 10vw;
        top: 10vw;
        width: 40vw;
        height: 40vw;
        max-width: 200px;
        max-height: 200px;
        background: url(../assets/logo_vache.png);
        background-size: contain;
        background-position: center;
        border: 1px solid $color_darkgray;

        @media all and (min-width: map-get($grid-breakpoints, md)) {
            right: 5vw;
            top: 5vw;
        }
    }
    .title {
        position: absolute;
        padding: 5vw;
        right: 10vw;
        bottom: 10vw;
        max-width: 80vw;
        text-align: right;
        background-color: rgba(255, 255, 255, .6);
        border: 1px solid $color_darkgray;
        font-size: .8em;

        h1 {
            text-transform: uppercase;
            color: $color_darkgreen;
            text-shadow: 2px 2px $color_darkgray;
            // font-family: 'Raleway';
        }

        h3 {
            color: $color_darkgray;
        }

        @media all and (min-width: map-get($grid-breakpoints, sm)) {
            font-size: 1em;
        }
        @media all and (min-width: map-get($grid-breakpoints, md)) {
            font-size: 1.8em;
            padding: 2vw;
            right: 5vw;
            bottom: 5vw;
        }
        @media all and (min-width: map-get($grid-breakpoints, lg)) {
            font-size: 2em;
        }
        @media all and (min-width: map-get($grid-breakpoints, lg)) {
            font-size: 1.3em;
        }
    }
}

.#{$prefix}news {
    #fb-page-container {
        margin: auto;
        max-width: 500px;
    }
    h2 {
        margin-left: auto;
        margin-right: auto;
        max-width: 500px;
    }
}

.#{$prefix}what {
    ul {
        padding-left: 0;
        li {
            padding-left: 40px;
            padding-top: 3px;
            margin-top: 5px;
            list-style-type: none;
            background-image: url(../assets/curseur_vert_30px.png);
            background-repeat: no-repeat;
            background-size: 32px;
        }
    }
    @media all and (min-width: map-get($grid-breakpoints, xl)) {
        ul {
            li {
                padding-left: 50px;
                background-image: url(../assets/curseur_vert.png);
                background-size: 48px;
            }
        }
    }

}

.#{$prefix}jeux {
    background-image: url(../assets/gamecovers.jpg);
    background-size: cover;
    .row {
        min-height: 100vh;
        align-items: center;
        .col-10 {
            background-color: rgba(255, 255, 255, .7);
            border: 1px solid $color_darkgray;    
        }
    }
}

.#{$prefix}footer {
    // display: flex;
    // flex-flow: row wrap;
    color: $color_white;
    background-color: $color_darkgray;

    > div {
        // flex: 1 100%;
        // @media all and (min-width: 800px) {
        //     flex: 1 50%;
        // }
    }
}

